.gettingStartedWholeBox {
  display: flex;
  flex-direction: column;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  animation: fadeInAnimation ease 0.5s;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  backdrop-filter: blur(8px);
  opacity: 0;
}
.hideBox {
  opacity: 0;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
    transform: translateY(25px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
