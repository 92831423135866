.gettingStartedWholeBox {
  display: flex;
  flex-direction: column;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  animation: fadeInAnimation ease 0.5s;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  backdrop-filter: blur(8px);
  opacity: 0;
}

/* .mainBox {

} */

.container {
  display: flex;
  flex-direction: column;
  padding: 6rem;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.01) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
}

.appTitle {
  width: 250px;
  font-size: 2rem;
  text-align: center;
}

.dissolveBox {
  background-image: url(https://s3.us-gov-west-1.amazonaws.com/resources.readyone.net/site_images/app_streams/test.png),
    linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.01) 0%,
      rgba(255, 255, 255, 0.1) 100%
    );
  background-size: contain;
  background-color: #212529;
  background-position: center;
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 3rem;
  cursor: pointer;
  transition: all 0.5s ease;
  animation: fadeInAnimation, transform ease 0.75s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

/* .open {

} */

.open .optionsContainer {
  opacity: 1;
}

.dissolveBox.open {
  cursor: default;
}

.optionsContainer {
  backdrop-filter: blur(6px);
  border-radius: 3rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.05);
}

.option {
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  font-size: 1.1rem;
}

.option:hover {
  background-color: rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
}

.closeBtn {
  position: absolute;
  cursor: pointer;
  background-color: #515457;
  backdrop-filter: blur(10px);
  border: 1px solid #9a9da0;
  border-radius: 50%;
  font-size: 4rem;
  padding: 1rem;
  transition: all 0.3s ease;
  opacity: 0;
  animation: fadeInSpin ease 0.75s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.closeBtn:hover {
  color: tomato;
  transition: all 0.3s ease;
}

/* 

These animations can be slimmed down, just for presentation purposes only.
That being said, I still need to make the entry/exit smoother, because it 
just kinda pops in and out 

*/

.option:nth-child(1) {
  animation: fadeIn1 ease 0.75s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.option:nth-child(2) {
  animation: fadeIn2 ease 0.75s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.option:nth-child(3) {
  animation: fadeIn3 ease 0.75s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.option:nth-child(4) {
  animation: fadeIn4 ease 0.75s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
    transform: translateY(25px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeInSpin {
  from {
    opacity: 0;
    transform: translateY(25px);
    transform: rotate(180deg);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
    transform: rotate(0deg);
  }
}

@keyframes fadeIn1 {
  from {
    opacity: 0;
    transform: translateY(-25px);
    transform: translateX(25px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
    transform: translateX(0px);
  }
}

@keyframes fadeIn2 {
  from {
    opacity: 0;
    transform: translateY(25px);
    transform: translateX(25px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
    transform: translateX(0px);
  }
}

@keyframes fadeIn3 {
  from {
    opacity: 0;
    transform: translateY(-25px);
    transform: translateX(-25px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
    transform: translateX(0px);
  }
}

@keyframes fadeIn4 {
  from {
    opacity: 0;
    transform: translateY(25px);
    transform: translateX(-25px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
    transform: translateX(0px);
  }
}
