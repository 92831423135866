.container {
  background-color: #212529;
  background-image: linear-gradient(135deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 100%);
  padding: 20px;
  border-radius: 8px;
  color: #fff;
  max-width: 600px;
  margin: 0 auto;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #ffffff;
}

.label {
  display: block;
  font-size: 16px;
  margin-bottom: 2px;
}

.select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: none;
  background-color: #1c1f26;
  color: #ffffff;
  font-size: 14px;
}


.textarea {
  background-color: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  padding: 10px;
  border: 1px solid #444b5a;
  border-radius: 5px;
  font-size: 1rem;
  resize: vertical;
  min-height: 100px;
  width: 100%;
  outline: none;
}

.textarea::placeholder {
  color: #777;
}

.buttonCont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.button {
  background-color: #1c1e29; /* Dark background color */
  color: #ffffff; /* White text color */
  border: 1px solid #444b5a; /* Border color to match dark theme */
  border-radius: 5px; /* Slightly rounded corners */
  padding: 10px 20px; /* Padding for button size */
  font-size: 1rem; /* Font size for readability */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Smooth transition */
  width: 150px;
}

.button:hover {
  background-color: #444b5a; /* Darker background on hover */
  border-color: #6a7391; /* Slightly lighter border on hover */
}

.button:active {
  background-color: #2e313d; /* Slightly lighter on click */
  border-color: #6a7391;
}

.button:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 0 2px #6a7391; /* Custom outline for accessibility */
}