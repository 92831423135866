.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  max-height: 100vh;
  max-width: unset;
}

.modalContent {
  max-width: 1250px;
  padding: 7rem;
  padding-top: 7rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 20px;
  overflow-y: scroll;
  scrollbar-width: none;
  position: relative;
  animation: fadeInDown 0.5s forwards;
  align-items: start;
  grid-template-rows: 75px 150px 150px 150px 150px;
  z-index: 4;
}

.modal {
  backdrop-filter: blur(8px);
}

.modal.hidden {
  animation: modalFadeOut 0.5s;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.modalContent.hidden {
  animation: fadeOutUp 0.5s forwards;
  animation-play-state: paused;
  visibility: hidden;
}

.modalContent::-webkit-scrollbar {
  width: 0;
}

.modal img {
  width: 5rem;
  margin: 1rem;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes modalFadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.jiggle img {
  animation: jiggle 0.5s linear infinite;
  transform: scale(1);
}

@keyframes jiggle {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.appStreamButtonContainer {
  position: relative;
}

.shrink {
  width: 50%;
}

.edit {
  flex-direction: row;

  transition: all 0.2s ease-in-out;
}
