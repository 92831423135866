.container {
  background-image: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
  background-color: #212529;
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.image {
  margin-right: 1rem;
}

.subtitle {
  font-size: 20px;
  font-weight: 300;
}
